@import url('https://fonts.googleapis.com/css2?family=Anton&family=Blaka&family=Fascinate+Inline&family=Kdam+Thmor+Pro&family=Nunito&family=Permanent+Marker&family=Righteous&family=Rubik:ital,wght@0,600;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	body{
		background-image: url('../src/images/esports-bg3-v3.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-attachment: fixed;
		min-height: 100%;
		overscroll-behavior: none;
	}
	
	.bg-playercard{
		background-image: url('../src/images/playercardbg.jpg');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		min-height: 100%;
		overscroll-behavior: none;
	}

    .textshadow {
        text-shadow: -1.5px -1.5px 0 #FFF, 1.5px -1.5px 0 #FFF, -1.5px 1.5px 0 #FFF, 1.5px 1.5px 0 #FFF;
    }

	.menu{
		@apply text-6xl top-10 absolute lg:invisible md:visible right-10;
	}

    .logo-size{
        height: 200px;
    }

    .link-underline {
		border-bottom-width: 0;
		background-image: linear-gradient(transparent, transparent), linear-gradient(rgb(190 18 57), rgb(190 18 57));
		background-size: 0px 5px;
		background-position: 0 90%;
		background-repeat: no-repeat;
        border-radius: 25%;
		transition: background-size .5s ease-in-out;
	}

	.link-underline-black {
		background-image: linear-gradient(transparent, transparent), linear-gradient(rgb(190 18 57), rgb(190 18 57))
	}

	.link-underline:hover{
		background-size: 100% 5px;
	}

	.section {
		@apply flex items-center justify-center px-5 pb-40;
	}

	.header-font {
		@apply text-emerald-600 font-bold textshadow;
	}

	.navLink {
		@apply link-underline link-underline-black hover:text-red-500 whitespace-nowrap italic pb-3 cursor-pointer header-font;
	}

	.navlink-active{
		border-bottom-width: 0;
		background-image: linear-gradient(transparent, transparent), linear-gradient(rgb(190 18 57), rgb(190 18 57));
		background-size: 100% 5px;
		background-position: 0 90%;
		background-repeat: no-repeat;
        border-radius: 25%;
		transition: background-size .5s ease-in-out;
		@apply text-red-500 whitespace-nowrap italic pb-3 cursor-pointer header-font
	}
}
